import * as React from "react";
import { createRoot } from "react-dom/client";
import { createGlobalStyle } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import Router from "./Router";
import { globalStyle } from "./styles";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`;

declare global {
  // tslint:disable-next-line
  interface Window {
    blockies: any;
  }
}

// 1. Get projectId
const projectId = "aef395aaf3023d533dadd32d9ef40404";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://rpc.ankr.com/eth",
};
const bsc = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://rpc.ankr.com/bsc",
};
const polygon = {
  chainId: 137,
  name: "Polygon",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: "https://rpc.ankr.com/polygon",
};

// 3. Create modal
const metadata = {
  name: "PayByBit",
  description: "Seamless Crypto Payment Solutions",
  url: "https://paybybit.net",
  icons: ["https://paybybit.net/images/26ba5126f20523ca84865235be00e7df.png"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet, bsc, polygon],
  projectId,
  themeMode: "light",
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <>
    <GlobalStyle />
    <Router />
  </>,
);
