import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import { handleStateTranslate } from "./helpers/utilities";

import { IAssetData } from "./helpers/types";
import { CONSTANT } from "./helpers/constant";

const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  display: block;
`;

const TransactionList = () => {
    const [data, setData] = useState([])

    const createData = () => {
        const address = "0x9d1af7EC1DC4486768E6c9e113fA412bA70DF7Cd"
        let URL;
        const base_url = window.location.origin;
        if (base_url === "https://paytest.iacceptbitcoin.io") {
            URL = `${CONSTANT.TEST_BASE_URL}/api/Transaction/listByCustomerWalletAddress?walletAddress=${address}`;
        } else {
            URL = `${CONSTANT.BASE_URL}/api/Transaction/listByCustomerWalletAddress?walletAddress=${address}`;
        }
        const settings = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        fetch(`${URL}`, settings)
            .then((res) => res.json())
            .then((data) => setData(data))
    }
    useEffect(() => {
        createData()
    }, [])


    return (
        <SLayout>
            <Column maxWidth={1000} spanHeight paddingBottom={110}>
                <Header
                    connected={true}
                    address={"0x9d1af7EC1DC4486768E6c9e113fA412bA70DF7Cd"}
                    chainId={137}
                    killSession={() => null}
                />
                <SContent>
                    <div className="d-flex align-content-end ms-3 mb-4">
                        <button type="button" style={{ fontSize: 12 }} className="btn btn-secondary" onClick={() => window.history.back()}>
                            بازگشت
                        </button>
                    </div>
                    {data ?
                        <div className="table-responsive">
                            <button type="button" style={{ fontSize: 12 }} className="btn btn-secondary" onClick={() => createData()}>
                                بررسی مجدد لیست
                            </button>
                            <table className="table" style={{ direction: 'rtl' }}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">وضعیت تراکنش</th>
                                        <th scope="col">هش تراکنش</th>
                                        <th scope="col">ارز پرداختی</th>
                                        <th scope="col">مبلغ ارز</th>
                                        <th scope="col">کد پیگیری</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item: any, index: number) => {
                                        const EHTBaseAddress = "https://etherscan.io/tx/";
                                        const BNBBaseAddress = "https://bscscan.com/tx/";
                                        const POLBaseAddress = "https://polygonscan.com/tx/";
                                        const baseURL =
                                            item.netowrkId === "1"
                                                ? EHTBaseAddress
                                                : item.netowrkId === "137"
                                                    ? POLBaseAddress
                                                    : BNBBaseAddress;
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{index}</th>
                                                <td>{handleStateTranslate(item.state)}</td>
                                                <td>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ color: "#4099ff" }}
                                                        href={baseURL + "" + item.transactionHash}
                                                    >
                                                        <span className="transactionHash">
                                                            {item.transactionHash}
                                                        </span></a>
                                                </td>
                                                <td>{item.cryptoCurrency}</td>
                                                <td>{item.cryptoAmount}</td>
                                                <td>{item.trackingCode}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : <Loader />}
                </SContent>
            </Column>
            <Footer />
        </SLayout >
    );
}

export default TransactionList;

