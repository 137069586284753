import { ITokenData } from "../helpers/types";


export const SUPPORTED_TOKENS: ITokenData[] = [
    {
        name: "Tether",
        symbol: "USDT",
        chain_id: 137,
        decimals: "6",
        contractAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        logo_path: "./assets/USDT.png",
    },
    {
        name: "Tether",
        symbol: "USDT",
        chain_id: 1,
        decimals: "6",
        contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        logo_path: "./assets/USDT.png",
    },
    {
        name: "Synergy Token",
        symbol: "SNG",
        chain_id: 137,
        decimals: "1",
        contractAddress: "0x64dc3d81fC83d8D65dA0af43100a53070c2e6ca4",
        logo_path: "./assets/erc20.svg",
    },
    {
        name: "Synergy Token",
        symbol: "SNG",
        chain_id: 80001,
        decimals: "1",
        contractAddress: "0x64dc3d81fC83d8D65dA0af43100a53070c2e6ca4",
        logo_path: "./assets/erc20.svg",
    },
    {
        name: "Binance Coin",
        symbol: "USDT",
        chain_id: 56,
        decimals: "18",
        contractAddress: "0x55d398326f99059ff775485246999027b3197955",
        logo_path: "./assets/USDT.png",
    }
];
