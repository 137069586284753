import * as React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

interface IColumnStyleProps {
  spanHeight: boolean;
  maxWidth: number;
  center: boolean;
  directionRow: boolean;
  between: boolean;
  paddingBottom: number;
  alignStart: boolean
}

interface IColumnProps extends IColumnStyleProps {
  children: React.ReactNode;
}

const SColumn = styled.div<IColumnStyleProps>`
  position: relative;
  width: 100%;
  height: ${({ spanHeight }) => (spanHeight ? "100%" : "auto")};
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  margin: 0 auto;
  padding-bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
  display: flex;
  flex-direction: ${({ directionRow }) => (directionRow ? "row" : "column")};
  align-items: ${({ alignStart }) => (alignStart ? "start" : "center")};;
  justify-content: ${({ center, between }) => (center ? "center" : (between ? "space-between" : "flex-start"))};
`;

const Column = (props: IColumnProps) => {
  const { children, spanHeight, maxWidth, center, directionRow, between, paddingBottom } = props;
  return (
    <SColumn {...props} spanHeight={spanHeight} maxWidth={maxWidth} center={center} directionRow={directionRow} between={between}
             paddingBottom={paddingBottom}>
      {children}
    </SColumn>
  );
};

Column.propTypes = {
  children: PropTypes.node.isRequired,
  spanHeight: PropTypes.bool,
  maxWidth: PropTypes.number,
  center: PropTypes.bool,
  directionRow: PropTypes.bool,
  between: PropTypes.bool,
  paddingBottom: PropTypes.number,
  alignStart: PropTypes.bool
};

Column.defaultProps = {
  spanHeight: false,
  maxWidth: 1000,
  center: false,
  directionRow: false,
  between: false,
  paddingBottom: 0,
  alignStart: false
};

export default Column;
