import * as React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ENApp from "./en/App";
import ENPayment from "./en/Payment";
import TransactionList from "./transactionList";
import TransactionListEN from "./en/transactionList";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ENApp/>} />
                <Route path="Payment" element={<ENPayment/>} />
                <Route path="/en/" element={<ENApp/>} />
                <Route path="/en/Payment" element={<ENPayment/>} />
                <Route path="/transactionList" element={<TransactionList/>} />
                <Route path="/en/transactionList" element={<TransactionListEN/>} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
