import axios, { AxiosInstance, AxiosResponse } from "axios";
import { IAssetData, IChainData, IGasPrice, IGasPrices, IParsedTx } from "./types";
import { getChainData, getSupportedTokens } from "./utilities";
import { CONSTANT } from './constant';

const polygonToken = "TMPK3B3T9Y3U9KT765Z6AVUW4RXVJV2Q7C";
const bscToken = "4RWYB5PFG3AQAHI781HWXKDRAY96ANXEDP";
const ethToken = "NPJB3KJB4BRA9S218BGUBUFAKCPRWPMF59";

const m_eth_api: AxiosInstance = axios.create({
  baseURL: "https://api.etherscan.io/",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const eth_api: AxiosInstance = axios.create({
  baseURL: "https://ethereum-api.xyz",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const polygon_api: AxiosInstance = axios.create({
  baseURL: "https://api.polygonscan.com",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const tpolygon_api: AxiosInstance = axios.create({
  baseURL: "https://api-testnet.polygonscan.com",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const bsc_api: AxiosInstance = axios.create({
  baseURL: "https://api.bscscan.com",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const tbsc_api: AxiosInstance = axios.create({
  baseURL: "https://api-testnet.bscscan.com",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const avax_api: AxiosInstance = axios.create({
  baseURL: "https://ethereum-api.xyz",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export async function selectAPI(chainId: number | undefined): Promise<AxiosInstance> {
  switch (chainId) {
    case 137:
      return polygon_api;
    case 43114:
      return avax_api;
    case 80001:
      return tpolygon_api;
    case 56:
      return bsc_api;
    case 97:
      return tbsc_api;
    default:
      return m_eth_api;
  }
}

export async function selectScanToken(chainId: number | undefined): Promise<string> {
  if (chainId === 137 || chainId === 80001) {
    return polygonToken;
  } else if (chainId === 56 || chainId === 97) {
    return bscToken;
  } else if (chainId === 1 || chainId === 42 || chainId === 5 || chainId === 4 || chainId === 3) {
    return ethToken;
  } else {
    return "";
  }
}


export async function apiGetAccountAssets(address: string | undefined, chainId: number | undefined): Promise<IAssetData[]> {
  const api = await selectAPI(chainId);
  const tokens = getSupportedTokens(chainId);
  const apiToken = await selectScanToken(chainId);
  let native_currency: IAssetData;
  let response: AxiosResponse;

  response = await api.get(`/api?module=account&action=balance&address=${address}&apikey=${apiToken}`);
  native_currency = getChainData(chainId).native_currency;
  native_currency.balance = response.data.result;
  const result: IAssetData[] = [{ ...native_currency }]
  for (let i = 0; i < tokens.length; i++) {
    native_currency = { ...tokens[i] }
    response = await api.get(`/api?module=account&action=tokenbalance&contractaddress=${tokens[i].contractAddress}&address=${address}&tag=latest&apikey=${apiToken}`);
    native_currency.balance = response.data.result;
    result.push({ ...native_currency })
  }

  //const { result } = response.data;
  return result;
}

export async function apiGetAccountTransactions(
  address: string,
  chainId: number,
): Promise<IParsedTx[]> {
  const response = await eth_api.get(`/account-transactions?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
}

export const apiGetAccountNonce = async (address: string | undefined, chainId: number | undefined): Promise<string> => {
  const { result } = { "result": "0" };
  // if (chainId === 1) {
  //   const response = await eth_api.get(`/account-nonce?address=${address}&chainId=${chainId}`);
  //   result = response.data.result;
  // }
  return result;
};

export const apiGetGasPrices = async (chainId: number | undefined): Promise<IGasPrices> => {

  const api = await selectAPI(chainId);
  const apiToken = await selectScanToken(chainId);
  // if (chainId === 1) {
  //   const response = await eth_api.get(`/gas-prices`);
  //   const { result } = response.data;
  //   return result;
  // }
  const response = await api.get(`/api?module=gastracker&action=gasoracle&apikey=${apiToken}`);
  const { result } = response.data;
  const slow: IGasPrice = { "time": 300, "price": result.SafeGasPrice }
  const average: IGasPrice = { "time": 120, "price": result.ProposeGasPrice }
  const fast: IGasPrice = { "time": 45, "price": result.FastGasPrice }
  const gasPrices: IGasPrices = { "timestamp": Date.now(), "slow": slow, "average": average, "fast": fast }
  return gasPrices;
};

export const apiGetCoinList = async (merchantCode: any, chainId: number): Promise<string> => {
  const base_url = window.location.origin
  let url
  if (base_url === 'https://merchant.paybybit.net') {
    url = `${CONSTANT.TEST_BASE_URL}/api/Payment/network?ChainId=${chainId}&MerchantCode=${merchantCode}`;
  } else {
    url = `${CONSTANT.BASE_URL}/api/Payment/network?ChainId=${chainId}&MerchantCode=${merchantCode}`;
  }

  const settings = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const fetchResponse = await fetch(`${url}`, settings);
  let dataRes
  fetchResponse.status === 400 ? dataRes = 'error' : dataRes = await fetchResponse.json();
  return dataRes
};

export const apiGetCoinListEN = async (merchantCode: any, chainId: number): Promise<string[]> => {
  const base_url = window.location.origin
  let url
  if (base_url === 'https://merchant.paybybit.net') {
    url = `${CONSTANT.TEST_BASE_URL_EN}/api/Payment/network?ChainId=${chainId}&MerchantCode=${merchantCode}`;
  } else {
    url = `${CONSTANT.BASE_URL_EN}/api/Payment/network?ChainId=${chainId}&MerchantCode=${merchantCode}`;
  }

  const settings = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const fetchResponse = await fetch(`${url}`, settings);
  let dataRes
  fetchResponse.status === 400 ? dataRes = 'error' : dataRes = await fetchResponse.json();
  return dataRes
};

export const apiGetCoinListPay = async (merchantCode: any, chainId: number): Promise<string[]> => {
  const base_url = window.location.origin
  let url
  if (base_url === 'https://merchant.paybybit.net') {
    url = `${CONSTANT.TEST_BASE_URL}/api/Payment/sell/network?ChainId=${chainId}&code=${merchantCode}`;
  } else {
    url = `${CONSTANT.BASE_URL}/api/Payment/sell/network?ChainId=${chainId}&code=${merchantCode}`;
  }
  const settings = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const fetchResponse = await fetch(`${url}`, settings);
  let dataRes
  fetchResponse.status === 400 ? dataRes = 'error' : dataRes = await fetchResponse.json();
  return dataRes
};