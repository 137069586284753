import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import Column from "../components/Column";
import Wrapper from "../components/Wrapper";
import Modal from "../components/Modal";
import ModalPrice from "../components/ModalPrice";
import ModalCrypto from "../components/ModalCrypto";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "../components/Loader";
import { colors, fonts, transitions } from "../styles";
import {
  apiGetAccountAssets,
  apiGetGasPrices,
  apiGetAccountNonce,
  apiGetCoinListEN,
  apiGetCoinListPay,
} from "../helpers/api";
import {
  convertCryptoToWei,
  convertWeiToCrypto,
  sanitizeHex,
  findDecimal,
  ellipseAddress,
  savePage,
  dateTime,
  getChainData,
} from "../helpers/utilities";
import { convertAmountToRawNumber, convertStringToHex } from "../helpers/bignumber";
import { IAssetData } from "../helpers/types";
import abi from "../components/SNG.ABI.json";
import MATIC from "../assets/polygon.png";
import info from "../assets/info.png";
import eth from "../assets/eth-icon.png";
import binance from "../assets/binance-icon.png";
import tether from "../assets/tether-icon.png";
import matic from "../assets/matic-icon.png";
import SNG from "../assets/sng.png";
import PayBy from "../assets/payby.png";
import Wallet from "../assets/wallet.png";
import AccountBalance from "../assets/AccountBalance.png";
import Exchange from "../assets/Exchange.png";
import Crypto from "../assets/Crypto.png";
import SelectBox from "../components/DropDown";
import { SUPPORTED_TOKENS } from "../helpers/tokens";
import { CONSTANT } from "../helpers/constant";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import success from "../assets/success.png";
import cryptoGroup from "../assets/cryptoGroup.png";
import { ethers } from "ethers";
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { isMobile } from "react-device-detect";
import { formatUnits, parseUnits } from "ethers/lib/utils";

interface ICloseButtonStyleProps {
  size: number;
  color: keyof typeof colors;
  onClick?: any;
}

const SCloseButton = styled.div<ICloseButtonStyleProps>`
  transition: ${transitions.short};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  right: ${({ size }) => `${size / 1.6667}px`};
  top: ${({ size }) => `${size / 1.6667}px`};
  opacity: 0.5;
  position: absolute;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    content: " ";
    height: ${({ size }) => `${size}px`};
    width: 2px;
    background: ${({ color }) => `rgb(${colors[color]})`};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
  background: #f5f5f5;
`;

const SContent = styled(Wrapper as any)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SLanding = styled(Column as any)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  min-height: 550px;
`;

const SButtonContainer = styled(Column as any)`
  border: 1px solid #d2d2d2;
  border-radius: 15px;
  margin: 50px 0;
  width: 49%;
`;

const SConnectButton = styled(Button as any)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 60px;
  width: 100%;
  background: url("${Wallet}") center no-repeat;
  background-size: contain;
  margin-top: 40px;
`;

const ComingSoonAccount = styled.div`
  height: 60px;
  width: 100%;
  margin: 25px 0 0;
  background: url("${AccountBalance}") center no-repeat;
  background-size: contain;
`;

const ComingSoonExchange = styled.div`
  height: 60px;
  width: 100%;
  margin: 25px 0 0;
  background: url("${Exchange}") center no-repeat;
  background-size: contain;
`;

const ComingSoonCrypto = styled.div`
  height: 60px;
  width: 100%;
  margin: 25px 0 0;
  background: url("${Crypto}") center no-repeat;
  background-size: contain;
  cursor: pointer;
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
  padding: 0px 20px;
`;

const SBalances = styled(SLanding as any)`
  flex-direction: column;
  align-items: center;
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STable = styled(SContainer as any)`
  flex-direction: column;
  text-align: left;
`;

const SRow = styled.div`
  width: 100%;
  display: flex;
  margin: 6px 0;
  direction: ltr;
  border-bottom: 0.5px solid #7b849b;
`;

const SKey = styled.div`
  width: 30%;
  color: #7b849b;
  text-align: left;
  font-size: 14px;
`;

const SValue = styled.div`
  width: 70%;
  font-family: monospace;
  text-align: right;
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
`;

const SButtonCancelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const SCancelButton = styled.button`
  width: 100%;
  color: #404756;
  border-radius: 6px;
  background: #f2f2f2;
  border: 1px solid #d2d2d2;
  line-height: 40px;
  font-size: 18px;
  box-shadow: none;
  margin: 12px;
`;

const STestButton = styled(Button as any)`
  width: 100%;
  margin: 12px;
  background: linear-gradient(90.06deg, #2f0074 0.05%, #4700af 99.95%);
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  line-height: 30px;
  font-size: 18px;
`;

const Payment = () => {
  const { address, chainId, isConnected: connected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [fetching, setFetching] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalPrice, setShowModalPrice] = useState<boolean>(false);
  const [showModalCrypto, setShowModalCrypto] = useState<boolean>(false);
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);
  const [errorNetwork, setErrorNetwork] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coinList, setCoinList] = useState<string[]>([]);
  const [currency, setCurrency] = useState<string>("");
  const [profileId, setProfileId] = useState<string>("");
  const [transactionStatus, setTransactionStatus] = useState<string[]>([]);
  const [assets, setAssets] = useState<IAssetData[]>([]);
  const [callBackUrl, setCallBackUrl] = useState<string>("");
  const [rialAmount, setRialAmount] = useState<string>("0");
  const [data, setData] = useState<any | null>(null);
  const [result, setResult] = useState<any | null>(null);
  const [countDownNumber, setCountDownNumber] = useState<number>(600);
  const [amount, setAmount] = useState<number>(0);
  const [transactionAmount, setTransactionAmount] = useState<number>(0);
  const [sellCode, setSellCode] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const [tokensPrice, setTokensPrice] = useState<TokensPrice[]>([]);
  const { open, close } = useWeb3Modal();
  interface TokensPrice {
    symbol: string;
    priceUsd: string;
  }
  useEffect(() => {
    if (trackingCode !== 0) {
      checkStatue();
    }
  }, [counter]);

  let x: TokensPrice[];
  useEffect(() => {
    fetch(`https://api.coincap.io/v2/assets/?ids=ethereum,binance-coin,polygon,tether`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        x = data.data.map((item: TokensPrice) => {
          return {
            symbol: item.symbol,
            priceUsd: item.priceUsd,
          };
        });
      })
      .then(() => {
        setTokensPrice(x);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [currency, rialAmount]);

  let trackingCode = 0;

  const checkStatue = async () => {
    const base_url = window.location.origin;
    let location;
    if (base_url === "https://merchant.paybybit.net") {
      location = `${CONSTANT.TEST_BASE_URL_EN}/api/Transaction/checkstatus?trackingCode=${trackingCode}`;
    } else {
      location = `${CONSTANT.BASE_URL_EN}/api/Transaction/checkstatus?trackingCode=${trackingCode}`;
    }

    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const fetchResponse = await fetch(`${location}`, settings);
      const result = await fetchResponse.json();
      setTransactionStatus(result);
    } catch (error) {
      return error;
    }
  };

  const postTransaction = async (txHash: string, _valueConvertToETH?: number) => {
    const base_url = window.location.origin;
    let location;
    if (base_url === "https://merchant.paybybit.net") {
      location = `${CONSTANT.TEST_BASE_URL_EN}/api/transaction/add/transactionHash`;
    } else {
      location = `${CONSTANT.BASE_URL_EN}/api/transaction/add/transactionHash`;
    }
    const dataReq = {
      TransactionHash: txHash,
      TrackingCode: trackingCode,
    };
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataReq),
    };
    try {
      await fetch(`${location}`, settings);
      const selectedToken = SUPPORTED_TOKENS.filter(
        (token: any) => token.symbol === currency.toString() && token.chain_id === chainId,
      )[0];

      if (selectedToken) {
        const formattedResult = {
          "TrackingCode:": trackingCode,
          "Amount of AED:": rialAmount,
          "TransactionHash:": txHash,
          "From Address:": ellipseAddress(address),
          "Gateway Name:": data?.gatewayName,
          "Crypto Amount:": `${transactionAmount} ${currency}`,
          "Date:": dateTime(),
        };
        setResult(formattedResult);
        setPendingRequest(false);
      } else {
        // format displayed result
        const formattedResult = {
          "TrackingCode:": trackingCode,
          "Amount of AED:": rialAmount,
          "TransactionHash:": txHash,
          "From Address:": ellipseAddress(address),
          "Gateway Name:": data?.gatewayName,
          "Crypto Amount:": `${_valueConvertToETH} ${currency}`,
          "Date:": dateTime(),
        };
        // display result
        setPendingRequest(false);
        setResult(formattedResult);
      }
    } catch (error) {
      return error;
    }
  };
  const connectWalletConnectV2Handler = async () => {
    setIsLoading(true);
    open();
    setIsLoading(false);
  };

  const getAccountAssets = async () => {
    const merchantCode = new URLSearchParams(window.location.search).get("id");
    setFetching(true);
    try {
      // get account balances
      const assetsRes = await apiGetAccountAssets(address, chainId);
      let coinListRes;
      if (chainId == 137 || chainId == 1 || chainId == 56) {
        coinListRes = await apiGetCoinListEN(merchantCode, chainId);
        console.log("coinListRes", coinListRes);

        if ((coinListRes as any) === "error") {
          setErrorNetwork(true);
        } else {
          setCoinList(coinListRes);
        }
      }
      setFetching(false);
      setAssets(assetsRes);
    } catch (error) {
      console.error(error);
      setFetching(false);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleModalPrice = () => {
    setShowModalPrice(!showModalPrice);
  };
  const toggleModalCrypto = () => {
    setShowModalCrypto(!showModalCrypto);
  };
  const sendAmount = async () => {
    const base_url = window.location.origin;
    let location;
    if (base_url === "https://merchant.paybybit.net") {
      location = `${CONSTANT.TEST_BASE_URL_EN}/api/payment/convert/en?srcAmount=${rialAmount}&destination=${currency}`;
    } else {
      location = `${CONSTANT.BASE_URL_EN}/api/payment/convert/en?srcAmount=${rialAmount}&destination=${currency}`;
    }
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const fetchResponse = await fetch(`${location}`, settings);
      const dataRes = await fetchResponse.json();
      console.log("dataRes:", dataRes);

      setTransactionAmount(dataRes);
      return dataRes;
    } catch (error) {
      return error;
    }
  };
  const transactionSend = async () => {
    const base_url = window.location.origin;
    let location;

    if (!connected) {
      return;
    }
    const transactionExact = await sendAmount();
    const postBeforeTransaction = async () => {
      const id = new URLSearchParams(window.location.search).get("id");

      if (base_url === "https://merchant.paybybit.net") {
        location = `${CONSTANT.TEST_BASE_URL_EN}/api/transaction/add`;
      } else {
        location = `${CONSTANT.BASE_URL_EN}/api/transaction/add`;
      }
      const date = new Date();
      const data = {
        FromWallet: address,
        toMerchantId: id,
        CryptoAmount: transactionExact,
        cryptoCurrency: currency,
        moneyCurrency: "AED",
        DateTime: date,
        money: rialAmount.replaceAll(",", ""),
        networkId: chainId?.toString(),
      };
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      try {
        const fetchResponse = await fetch(`${location}`, settings);
        const result = await fetchResponse.json();
        trackingCode = Number(result);
      } catch (error) {
        return error;
      }
    };

    await postBeforeTransaction();

    const selectedToken = SUPPORTED_TOKENS.filter(
      (token: any) => token.symbol === currency.toString() && token.chain_id === chainId,
    )[0];

    let toAddress;

    if (base_url === "https://payment.paybybit.net/en/") {
      toAddress = CONSTANT.MAIN_WALLET_ADDRESS;
    } else {
      toAddress = CONSTANT.TEST_WALLET_ADDRESS;
    }

    // if (selectedToken) {
    //   if (walletProvider) {
    //     const provider = new ethers.providers.Web3Provider(walletProvider);
    //     const contractAddress = selectedToken.contractAddress;
    //     const token = new ethers.Contract(contractAddress, abi, provider.getSigner());

    //     let number;
    //     let amount;
    //     if (selectedToken.symbol === "SNG") {
    //       number = Number(amount);
    //       amount = Math.trunc(number * 100) / 10;
    //     } else {
    //       number = Number(amount).toString();
    //       const decimal = findDecimal(parseInt(selectedToken.decimals));
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       // @ts-ignore
    //       amount = web3.utils.toWei(number, decimal);
    //     }

    //     // open modal
    //     toggleModal();

    //     // toggle pending request indicator
    //     setPendingRequest(true);
    //     const callAPITransactionStatus = setInterval(() => {
    //       if (Object.values(transactionStatus)[1] === "Approved") {
    //         clearInterval(callAPITransactionStatus);
    //         postTransaction(Object.values(transactionStatus)[0]);
    //         setCountDownNumber(0);
    //       }
    //       setCounter(counter + 1);
    //     }, 5000);

    //     await token.functions
    //       .transfer(toAddress, amount)
    //       .then((result: any) => {
    //         // clearInterval(callAPITransactionStatus)
    //         // setCountDownNumber(0)
    //         console.log(result);
    //       })
    //       .catch((err) => {
    //         console.log("errrror", err);
    //         setPendingRequest(false);
    //         // clearInterval(callAPITransactionStatus)
    //       });
    //     return;
    //   }
    // }
    // from
    const from = address;

    // to
    const to = toAddress;

    // nonce
    const _nonce = await apiGetAccountNonce(address, chainId);
    const nonce = sanitizeHex(convertStringToHex(_nonce));

    // gasPrice
    const gasPrices = await apiGetGasPrices(chainId);
    const _gasPrice = gasPrices.fast.price;
    const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    // gasLimit
    const _gasLimit = 21000;
    const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    const weiToCryptoConvert = convertWeiToCrypto(transactionExact, currency.toString(), chainId);

    const crypto = weiToCryptoConvert;
    const _value = crypto;
    const value = sanitizeHex(convertStringToHex(_value));

    const tx = {
      from,
      to,
      nonce,
      gasPrice,
      gasLimit,
      value,
    };

    try {
      // open modal
      toggleModal();
      window.scrollTo(0, 0);
      // toggle pending request indicator
      setPendingRequest(true);
      // send transaction
      const cryptoToWeiConvert = convertCryptoToWei(_value, currency.toString(), chainId);
      const _valueConvertToETH = cryptoToWeiConvert;

      const callAPITransactionStatusCoin = setInterval(() => {
        if (Object.values(transactionStatus)[1] === "Approved") {
          clearInterval(callAPITransactionStatusCoin);
          postTransaction(Object.values(transactionStatus)[0], _valueConvertToETH);
          setCountDownNumber(0);
        }
        setCounter(counter + 1);
      }, 5000);
      let result;
      if (walletProvider) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        if (currency === "USDT") {
          const token = new ethers.Contract(
            selectedToken.contractAddress,
            abi,
            provider.getSigner(),
          );
          const decimal = findDecimal(parseInt(selectedToken.decimals));
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const txAmount = parseUnits(transactionExact.toString(), decimal);
          result = await token.functions.transfer(toAddress, txAmount);
        } else {
          result = await provider?.getSigner()?.sendTransaction(tx);
        }
      }

      if (result) {
        console.log("result:", result);
        checkStatue();
        // clearInterval(callAPITransactionStatusCoin
        postTransaction(result.hash, _valueConvertToETH);
        setCountDownNumber(0);
      }
    } catch (error) {
      console.error(error);
      setPendingRequest(false);
      setResult(null);
    }
  };

  const handelSubmitPopupAmount = () => {
    const price = window.document.getElementById("fixPrice") as HTMLInputElement;
    const event = price.value;
    if (event === "") {
      return;
    }
    setRialAmount(event);
    setShowModalPrice(false);
  };

  useEffect(() => {
    if (currency != "") {
      setCurrency("");
    }
  }, [chainId]);

  useEffect(() => {
    if (tokensPrice.length > 0) {
      switch (currency) {
        case tokensPrice[0].symbol:
          setAmount(ConvertToAEDwithFee(tokensPrice[0].priceUsd));
          break;
        case tokensPrice[1].symbol:
          setAmount(ConvertToAEDwithFee(tokensPrice[1].priceUsd));
          break;
        case tokensPrice[2].symbol:
          setAmount(ConvertToAEDwithFee(tokensPrice[2].priceUsd));
          break;
        case tokensPrice[3].symbol:
          setAmount(ConvertToAEDwithFee(tokensPrice[3].priceUsd));
          break;
        default:
          setAmount(0);
          break;
      }
    }
  }, [currency, rialAmount]);

  const handelChangeCurrency = (event: any) => {
    setCurrency(event);
  };

  const ConvertToAEDwithFee = (usdPrice: string) => {
    return (Number(rialAmount) / (Number(usdPrice) * 3.67)) * 1.05;
  };

  const fetchData = async () => {
    const params = new URLSearchParams(window.location.search).get("id");
    const getApiData = async () => {
      const base_url = window.location.origin;
      let url;
      if (base_url === "https://merchant.paybybit.net") {
        url = `${CONSTANT.TEST_BASE_URL_EN}/api/payment/getpayment?id=${params}`;
      } else {
        url = `${CONSTANT.BASE_URL_EN}/api/payment/getpayment?id=${params}`;
      }
      const response = await fetch(url).then((response) => response.json());
      // update the state
      setData(response);
    };
    getApiData();
  };

  const openPriceModal = () => {
    setShowModalPrice(true);
  };

  const comingSoonCrypto = () => {
    setShowModalCrypto(true);
  };

  useEffect(() => {
    fetchData();
    if (connected && address !== undefined) {
      getAccountAssets();
      if (rialAmount === "0") {
        setShowModalPrice(true);
      }
    }
  }, [connected, chainId, address]);

  useEffect(() => {
    const price = window.document.getElementById("fixPrice") as HTMLInputElement;
    price.setAttribute("autofocus", "autofocus");
    price.focus();
    }, []);
 
  return (
    <SLayout>
      <Column maxWidth={1000} spanHeight paddingBottom={200}>
        <Header connected={connected} address={address} chainId={chainId} />
        <SContent>
          {!connected || address === undefined ? (
            <>
              <div className="appDescription">
                All transactions secured by PaybyBit, and payments will be made only with your
                approval.
              </div>
              <div className="content">
                <div className="connectWallet">
                  <img style={{ marginTop: -43, background: "#F5F5F5" }} src={PayBy} alt="" />
                  <SConnectButton
                    color="#fff"
                    outline
                    left
                    onClick={() => connectWalletConnectV2Handler()}
                    fetching={fetching}
                  ></SConnectButton>
                  <ComingSoonAccount></ComingSoonAccount>
                  <ComingSoonExchange></ComingSoonExchange>
                  <ComingSoonCrypto onClick={() => comingSoonCrypto()}></ComingSoonCrypto>
                </div>
                <div className="appSupportedNetwork">
                  <div
                    style={{
                      padding: "0 20px",
                      margin: "-23px auto 0",
                      background: "#F5F5F5",
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h6
                      className="fw-bold"
                      style={{
                        display: "table",
                        marginRight: 10,
                      }}
                    >
                      Supported Cryptocurrencies{" "}
                    </h6>
                    <span>(for now)</span>
                  </div>
                  <div className="d-flex justify-content-between mb-4 mt-md-5 flex-wrap">
                    <div className="coinIconContainer">
                      <img className="coinIcon" src={eth} alt="eth" />
                      <span style={{ color: "#404756", fontSize: 13, marginTop: 10 }}>ETH</span>
                    </div>
                    <div className="coinIconContainer">
                      <img className="coinIcon" src={binance} alt="binance" />
                      <span style={{ color: "#404756", fontSize: 13, marginTop: 10 }}>BNB</span>
                    </div>
                    <div className="coinIconContainer">
                      <img className="coinIcon" src={matic} alt="matic" />
                      <span style={{ color: "#404756", fontSize: 13, marginTop: 10 }}>MATIC</span>
                    </div>
                    <div className="coinIconContainer">
                      <img className="coinIcon" src={tether} alt="tether" />
                      <span
                        style={{
                          color: "#404756",
                          fontSize: 13,
                          marginTop: 10,
                        }}
                      >
                        USDT(ERC20)
                      </span>
                    </div>
                    <div className="coinIconContainer">
                      <img className="coinIcon" src={tether} alt="tether" />
                      <span
                        style={{
                          color: "#404756",
                          fontSize: 13,
                          marginTop: 10,
                        }}
                      >
                        USDT(BEP20)
                      </span>
                    </div>
                    <div className="coinIconContainer">
                      <img className="coinIcon" src={tether} alt="tether" />
                      <span
                        style={{
                          color: "#404756",
                          fontSize: 13,
                          marginTop: 10,
                        }}
                      >
                        USDT(POYGON)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <SBalances>
              <div dir="rtl" style={{ width: "100%" }}>
                <div className="justify-content-center mt-5 container">
                  <div className="paymentInfo">
                    <div className="col-sm-12 p-3 d-flex justify-content-center flex-column align-items-center">
                      <img
                        src={"data:image/png;base64," + data?.logo}
                        style={{ width: 60 }}
                        className="col-xs-12 col-sm-3 mb-2"
                      />
                      <h6 className="mt-1 mb-1">Name of recipient: {data?.companyName}</h6>
                      <span>Gateway Name: {data?.gatewayName}</span>
                    </div>
                  </div>
                  <div className="paymentContent">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        paddingLeft: "12px",
                      }}
                    >
                      {isMobile && <w3m-account-button />}
                    </div>
                    <div className="card-body paymentEnter">
                      <div className="text-danger" />
                      <div className="row flex-wrap mb-2 p-3">
                        <div className="form-group col-12">
                          <label className="priceLabel w-100 justify-content-end">
                            <span>Amount ($ AED)</span>
                          </label>
                          <input
                            id="price"
                            type="number"
                            onClick={() => openPriceModal()}
                            value={rialAmount}
                            className="form-control shadow-none w-100 text-start"
                          />
                          <span className="text-danger" />
                        </div>
                        <div className="currency">
                          <w3m-network-button />
                          {coinList.length ? (
                            <SelectBox
                              data={coinList}
                              assets={assets}
                              handelChange={(e: any) => handelChangeCurrency(e)}
                              style={{
                                left: 0,
                                position: "relative",
                                margin: 0,
                              }}
                            />
                          ) : (
                            <Loader size={30} />
                          )}
                        </div>
                        <div className="amount">
                          <label className="priceLabel w-100 justify-content-end">
                            <span>Equivalent to the amount</span>
                          </label>
                          <span className="price" id="latestPrice">
                            {amount}
                          </span>
                        </div>
                        <span className="mt-2 text-start" style={{ fontSize: 10 }}>
                          Data provided by{" "}
                          <a
                            target="_blank"
                            href="https://www.coingecko.com/en/api"
                            rel="noreferrer"
                          >
                            CoinGecko
                          </a>
                        </span>
                      </div>
                      <div>
                        <STestButtonContainer>
                          {errorNetwork && (
                            <p style={{ color: "red" }}>
                              You are not allowed to pay in this network.
                            </p>
                          )}
                          {!errorNetwork && (
                            <STestButton left onClick={() => transactionSend()}>
                              {"Pay"}
                            </STestButton>
                          )}
                        </STestButtonContainer>
                        <SButtonCancelContainer>
                          <SCancelButton onClick={() => location.reload()}>Cancel</SCancelButton>
                        </SButtonCancelContainer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="successModal" role="dialog">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Payment Result</h4>
                        <button type="button" className="close border-0" data-dismiss="modal">
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="text-success">Payment was successful.</p>
                        <div className="d-flex flex-column">
                          <span>Tracking Code: </span>
                          <span id="hashCode" />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">
                          Return to main page
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<h5 className="border-top mt-5 pt-4">Wallet status</h5>
                                {!fetching ? (
                                    <AccountAssets chainId={chainId} assets={assets}/>
                                ) : (
                                    <Column center>
                                        <SContainer>
                                            <Loader/>
                                        </SContainer>
                                    </Column>
                                )}*/}
            </SBalances>
          )}

          {/*{result &&
                            <SBalances>
                                <div style={{width: '100%'}}>
                                    <div className="justify-content-center mt-5 container">
                                        <div className="successHeader">
                                            <div
                                                className="col-sm-12 p-3 d-flex justify-content-center flex-column align-items-center">
                                                <img src={success} style={{width: 60}}
                                                     className="col-xs-12 col-sm-3 mb-2"/>
                                                <h6 className="mt-1 mb-1">Transfer Successful!</h6>
                                                <span style={{color: '#7B849B'}}>Your money has been transferred <br/>successfully!</span>
                                            </div>
                                        </div>
                                        <div className="successContent">
                                            <div className="card-body paymentEnter">
                                                <STable>
                                                    {Object.keys(result).map(key => {
                                                        const EHTBaseAddress = 'https://etherscan.io/tx/';
                                                        const BNBBaseAddress = 'https://bscscan.com/tx/';
                                                        const POLBaseAddress = 'https://polygonscan.com/tx/';
                                                        const baseURL = chainId === 1 ? EHTBaseAddress : (chainId === 137 ? POLBaseAddress : BNBBaseAddress)
                                                        return (
                                                            <SRow key={key}>
                                                                <SKey>{key}</SKey>
                                                                <SValue>{key === "TransactionHash:" ?
                                                                    <a target="_blank" rel="noreferrer" style={{color: '#4099ff'}}
                                                                       href={baseURL + '' + result[key].toString()}>{ellipseAddress(result[key].toString())}</a> :
                                                                    (key === "TrackingCode:" ? <span
                                                                        style={{color: '#000'}}>{result[key].toString()}</span> : result[key].toString())}</SValue>
                                                            </SRow>
                                                        )
                                                    })
                                                    }
                                                    <input className="receipt" type="button" value="Download Receipt" onClick={savePage}/>
                                                    <input className="continue" type="button" value="Continue to website"/>
                                                </STable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SBalances>
                        }*/}
        </SContent>
      </Column>

      <Footer tel={"Support: "} email={"Email: "} power={"Powered By"} />
      <Modal show={showModal} toggleModal={() => toggleModal()}>
        <SBalances>
          <div style={{ width: "100%" }}>
            <div className="justify-content-center mt-5 container">
              {pendingRequest ? (
                <div className="successContent">
                  <div className="card-body paymentEnter">
                    <SModalContainer>
                      <div className="d-flex justify-content-center">
                        <CountdownCircleTimer
                          isPlaying
                          duration={countDownNumber}
                          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                          colorsTime={[7, 5, 2, 0]}
                          size={70}
                          strokeWidth={3}
                        >
                          {({ remainingTime }: any) => {
                            const minutes = Math.floor((remainingTime % 3600) / 60);
                            const seconds = remainingTime % 60;

                            return `${minutes}:${seconds}`;
                          }}
                        </CountdownCircleTimer>
                      </div>
                      <SModalTitle>{"Transaction in progress"}</SModalTitle>
                      <span style={{ color: "#7B849B", marginTop: "-16px", display: "block" }}>
                        Please don’t leave this page
                      </span>
                      <SContainer>
                        <img src={info} alt="info" style={{ marginTop: 200 }} />
                        <SModalParagraph>
                          {
                            "If your transaction is incomplete and your balance has been reduced, please first check the transaction status in the Transactions section to confirm whether it has been completed. Then, contact our support team for further assistance."
                          }
                        </SModalParagraph>
                      </SContainer>
                    </SModalContainer>
                  </div>
                </div>
              ) : result ? (
                <SModalContainer id="paymentSuccess">
                  <div className="successHeader">
                    <div className="col-sm-12 p-3 d-flex justify-content-center flex-column align-items-center">
                      <img
                        src={success}
                        style={{ width: 60 }}
                        className="col-xs-12 col-sm-3 mb-2"
                      />
                      <h6 className="mt-1 mb-1">Transfer Successful!</h6>
                      <span style={{ color: "#7B849B" }}>
                        Your money has been transferred <br />
                        successfully!
                      </span>
                    </div>
                  </div>
                  <div className="successContent">
                    <div className="card-body paymentEnter">
                      <STable>
                        {Object.keys(result).map((key) => {
                          const EHTBaseAddress = "https://etherscan.io/tx/";
                          const BNBBaseAddress = "https://bscscan.com/tx/";
                          const POLBaseAddress = "https://polygonscan.com/tx/";
                          const baseURL =
                            chainId === 1
                              ? EHTBaseAddress
                              : chainId === 137
                              ? POLBaseAddress
                              : BNBBaseAddress;
                          return (
                            <SRow key={key}>
                              <SKey>{key}</SKey>
                              <SValue>
                                {key === "TransactionHash:" ? (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "#4099ff" }}
                                    href={baseURL + "" + result[key].toString()}
                                  >
                                    {ellipseAddress(result[key].toString())}
                                  </a>
                                ) : key === "TrackingCode:" ? (
                                  <span style={{ color: "#000" }}>{result[key].toString()}</span>
                                ) : (
                                  result[key].toString()
                                )}
                              </SValue>
                            </SRow>
                          );
                        })}
                        <input
                          className="receipt"
                          type="button"
                          value="Download Receipt"
                          onClick={savePage}
                        />
                        <input
                          className="continue"
                          type="button"
                          value="Continue to website"
                          onClick={() => setShowModal(false)}
                        />
                      </STable>
                    </div>
                  </div>
                </SModalContainer>
              ) : (
                <>
                  <div className="successHeader">
                    <div className="col-sm-12 p-3 d-flex justify-content-center flex-column align-items-center">
                      <SModalContainer>
                        <SModalTitle>{"Your request was rejected"}</SModalTitle>
                      </SModalContainer>
                    </div>
                  </div>
                  <div className="successContent">
                    <div className="card-body paymentEnter">
                      <input
                        className="continue"
                        type="button"
                        value="Continue to website"
                        onClick={() => setShowModal(false)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </SBalances>
      </Modal>
      <ModalPrice show={showModalPrice} toggleModal={() => toggleModalPrice()}>
        <div className="bg-light rounded-3 col-sm-3 mx-auto p-5" style={{ marginTop: 200 }}>
          <span style={{ fontSize: 20 }}>Enter amount</span>
          <div
            className="d-flex justify-content-center align-items-center border-bottom border-dark mx-auto my-4"
            style={{ width: 190 }}
          >
            <input
              id="fixPrice"
              type="number"
              className="form-control bg-transparent border-0 shadow-none w-100 text-start"
              style={{ height: 30 }}
            />
            <span>AED</span>
          </div>
          <button
            style={{
              padding: "14px 28px",
              color: "#fff",
              background: "linear-gradient(90.06deg, #7012F6 0.05%, #9853FC 99.95%)",
              borderRadius: 7,
            }}
            onClick={() => handelSubmitPopupAmount()}
          >
            {"Ok"}
          </button>
        </div>
      </ModalPrice>
      <ModalCrypto show={showModalCrypto} toggleModal={() => toggleModalCrypto()}>
        <div
          className="bg-light rounded-3 col-sm-3 mx-auto p-5"
          style={{ marginTop: 200, position: "relative" }}
        >
          <SCloseButton size={25} color={"dark"} onClick={() => toggleModalCrypto()} />
          <img src={cryptoGroup} alt="" />
        </div>
      </ModalCrypto>
    </SLayout>
  );
};

export default Payment;
