import React from "react";
import Column from "../../components/Column";
import FooterLogo from "../../assets/colored-logo2.png";
import TelIcon from "../../assets/tel-icon.png";
import EmailIcon from "../../assets/email-icon.png";
import coingeckoLogo from "../../assets/coingecko_logo.png";

interface Props {
  power?: string;
  email?: string;
  tel?: string;
}

const Footer = ({ power = "قدرت گرفته از", email = "ایمیل: ", tel = "تلفن پشتیبانی:" }: Props) => {
  return (
    <div className="footerEN">
      <Column maxWidth={1000} spanHeight between alignStart>
        <div style={{ lineHeight: 3 }}>
          <img style={{ marginRight: 10, width: 20 }} src={TelIcon} alt="tel" />
          <a href="tel: +4367761482340">+43 677 6148 2340</a>
        </div>
        <div style={{ lineHeight: 3 }}>
          <img style={{ marginRight: 10, width: 20 }} src={EmailIcon} alt="email" />
          <a href="mailto: info@paybybit.net">support@paybybit.net</a>
        </div>
        <div style={{ direction: "rtl", lineHeight: 3 }}>
          <a href="https://www.coingecko.com/" target="_blank" rel="noreferrer">
            Data generated by CoinGecko
          </a>
          <img style={{ width: 25, marginRight: 5 }} src={coingeckoLogo} alt="" />
        </div>
      </Column>
    </div>
  );
};

export default Footer;
