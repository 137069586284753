import React from "react";
import Column from "./Column";
import FooterLogo from "../assets/colored-logo2.png";
import TelIcon from "../assets/tel-icon.png";
import EmailIcon from "../assets/email-icon.png";

interface Props {
    power?: string;
    email?: string;
    tel?: string;
}

const Footer = ({power = 'قدرت گرفته از', email = 'ایمیل: ', tel = 'تلفن پشتیبانی:'}: Props) => {
    return (
        <div className="footer">
            <Column maxWidth={1000} spanHeight directionRow between>
                <div style={{direction: 'rtl'}}>
                    <img style={{marginLeft: 10, width: 20}} src={TelIcon} alt="tel"/>
                    <a href="tel: +4367761482340">+43 677 6148 2340</a>
                </div>
                <div style={{direction: 'rtl'}}>
                    <img style={{marginLeft: 10, width: 20}} src={EmailIcon} alt="email"/>
                    <a href="mailto: info@paybybit.net">info@paybybit.net</a>
                </div>
                <div style={{direction: 'rtl'}}>
                    <img className="ms-2" style={{width: 30}} src={FooterLogo} alt=""/>
                    <span>{power} PayByBit.net</span>
                </div>
            </Column>
        </div>
    );
}

export default Footer;