import * as React from "react";
import MATIC from '../assets/polygon.png';
import USDT from '../assets/USDT.png';
import ETH from '../assets/eth.png';
import BNB from '../assets/bnb.png';
import SNG from '../assets/sng.png';
import USDC from '../assets/USDC.png';
import {convertAmountFromRawNumber, handleSignificantDecimals} from "../helpers/bignumber";

class Dropdown extends React.Component<any, any> {

    constructor(props:any) {
        super(props)

        this.state = {
            isOpen: false,
            haveText: "",
            setColor: false,
            selected: ''
        }

        this.handleCoinList()
    }
    handleCoinList = () => {
        const newData = this.props.data?.map((item:any) => {
            return this.props.assets.filter((itemAsset:any) => itemAsset.symbol === item)
        })
        return newData
    }
    render() {
        const {isOpen, haveText} = this.state;
        const data = this.props.data;
        const assets = this.props.assets;

        return (
            <div
                //className={isOpen ? "dropdown active" : "dropdown"}
                //onClick={this.handleClick}
                style={this.props.style}>
                {/*<div className="dropdown__text">*/}
                {/*    {!haveText ? data[0] : haveText}*/}
                {/*    {!haveText ? this.handelLogo(data[0]) : this.handelLogo(haveText)}*/}
                {/*</div>*/}
                {this.itemList(this.handleCoinList())}
            </div>
        )
    }



    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handelLogo = (item: string) => {
        switch (item) {
            case "SNG":
                return <img style={{marginRight: 5}} src={SNG}/>
            case "MATIC":
                return <img style={{marginRight: 5}} src={MATIC}/>
            case "USDT":
                return <img style={{marginRight: 5}} src={USDT}/>
            case "ETH":
                return <img style={{marginRight: 5}} src={ETH}/>
            case "BNB":
                return <img style={{marginRight: 5}} src={BNB}/>
            case "USDC":
                return <img style={{marginRight: 5}} src={USDC}/>
            default: return ;
        }
    }

    handleText = (item:any, event: any) => {
        this.setState({
            haveText: event.currentTarget.children[1].textContent,
            setColor: (current:boolean) => !current,
            selected: item
        })
        this.props.handelChange(event.currentTarget.children[1].textContent)
    }

    handleFocus = (item:any) => {
        const selected:any =document.getElementById(item);
        selected.style.border = "1px solid red"
        selected.style.boxShadow = "none"
    }

    handleMouseLeave = (item:any) => {
        const selected:any =document.getElementById(item);
        selected.style.border = "1px solid #ced4da"
    }

    itemList = (props: any) => {
        const list = props.map((item: any, index: number) => {
            return item?.map((item2: any, indexItem: number) => {
                return (
                    <div
                        onClick={(event) =>this.handleText(item2.symbol, event)}
                        tabIndex={1}
                       // onFocus={() => this.handleFocus(item2.symbol)}
                        //onBlur={() => this.handleMouseLeave(item2.symbol)}
                        className="form-control coinItem bg-transparent mt-2 border-2 lh-base d-flex justify-content-between align-items-center"
                        id={item2.symbol}
                        key={item2.symbol}
                        style={this.state.setColor && this.state.selected === item2.symbol ? {border: '1px solid red', cursor: 'pointer', boxShadow: 'none'} : {border: '1px solid #ced4da', cursor: 'pointer', boxShadow: 'none'}}
                    >
                        <div className="d-flex flex-column" style={{fontSize: 12}}>
                            <span>:balance</span>
                            <span>{handleSignificantDecimals(convertAmountFromRawNumber(item2.balance, Number(item2.decimals)), 8)}</span>
                        </div>
                        <div id="symbol">
                            {item2.symbol}
                            {this.handelLogo(item2.symbol)}
                        </div>
                    </div>
                )
            })
        });

        return (
            <div> { list } </div>
        )
    }

}

export default Dropdown