import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Blockie from "../../components/Blockie";
import { ellipseAddress, getChainData } from "../../helpers/utilities";
import { transitions } from "../../styles";
import Logo from "../../assets/logo2.png";
import Modal from "../../components/Modal";
import back from "../../assets/back.png";
import { useDisconnect, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { isMobile } from "react-device-detect";

const SHeader = styled.div`
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const SActiveAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-weight: 500;
`;

const SActiveChain = styled(SActiveAccount as any)`
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  & p {
    font-size: 0.8em;
    margin: 0;
    padding: 0;
  }
  & p:nth-child(2) {
    font-weight: bold;
  }
`;

const SBlockie = styled(Blockie as any)`
  margin-right: 10px;
`;

interface IHeaderStyle {
  connected: boolean;
}

const SAddress = styled.p<IHeaderStyle>`
  transition: ${transitions.base};
  font-weight: bold;
  margin: ${({ connected }) => (connected ? "10px auto 0.7em" : "0")};
`;

const SUnsupportedChain = styled.div`
  transition: ${transitions.base};
  font-weight: bold;
  color: red;
`;

const SDisconnect = styled.div<IHeaderStyle>`
  background: linear-gradient(90.06deg, #bf6bf4 0.05%, #722ebf 99.95%);
  border-radius: 8px;
  border: 0;
  padding: 5px 20px;
  color: #fff;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
`;

interface IHeaderProps {
  connected: boolean;
  address: string | undefined;
  chainId: number | undefined;
}

const Header = ({ connected, address, chainId }: IHeaderProps) => {
  let activeChain = null;
  const [state, setState] = useState(false);
  const toggleModal = () => setState((state) => !state);
  try {
    activeChain = chainId ? getChainData(chainId).name : null;
  } catch (error) {
    console.error(error);
  }

  useEffect(() => {
    setState(false);
  }, [connected]);

  const { isConnected } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();

  const disconnectwallet = () => {
    if (isConnected) {
      disconnect();
    }
  };

  return (
    <SHeader>
      {/*{connected && (
        <SActiveChain>
          {activeChain ? (
            <>
              <p>Connected to</p>
              <p>{activeChain}</p>
            </>
          ) : (
            <SUnsupportedChain>
              <p>Chain not supported.</p>
              <p>Please switch to a supported chain in your wallet.</p>
            </SUnsupportedChain>
          )}
          <div>
            <Link className="btn btn-secondary" style={{fontSize: 12}} to="/en/transactionList">History</Link>
          </div>
        </SActiveChain>
      )}
      {address && (
        <SActiveAccount>
          <SBlockie address={address} />
          <SAddress connected={connected}>{ellipseAddress(address)}</SAddress>
          <SDisconnect connected={connected} onClick={killSession}>
            {"Disconnect"}
          </SDisconnect>
        </SActiveAccount>
      )}*/}
      <img src={Logo} />
      <nav style={{ display: "flex", alignItems: "center" }}>
        {isConnected && !isMobile && address != undefined && (
          <Link className="btn-secondary me-4" style={{ fontSize: 12 }} to="/en/transactionList">
            Transactions
          </Link>
        )}
        {isConnected && !isMobile && address != undefined && <w3m-account-button />}
      </nav>
      <Modal show={state} toggleModal={toggleModal}>
        <div style={{ maxWidth: 1000, margin: "auto", textAlign: "left" }}>
          <img src={Logo} />

          <div style={{ textAlign: "left" }}>
            <img
              src={back}
              style={{ marginTop: 80, width: 30, cursor: "pointer" }}
              onClick={() => {
                setState(false);
                window.history.back();
              }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold" }}>Wallet</span>
            {address && (
              <SActiveAccount>
                <span style={{ marginTop: 40 }}>Wallet Address:</span>
                <SAddress connected={connected}>{ellipseAddress(address)}</SAddress>
                <SDisconnect connected={connected} onClick={() => disconnectwallet()}>
                  {"Disconnect"}
                </SDisconnect>
              </SActiveAccount>
            )}
          </div>
        </div>
      </Modal>
    </SHeader>
  );
};

export default Header;
