import React, { useState } from "react";
import styled from "styled-components";
import Column from "../components/Column";
import Wrapper from "../components/Wrapper";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "../components/Loader";
import { CONSTANT } from "../helpers/constant";
import back from "../assets/back.png";
import refresh from "../assets/refresh.png";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  display: block;
`;

const TransactionList = () => {
  const [data, setData] = useState([]);
  const { address, chainId, isConnected: connected } = useWeb3ModalAccount();

  const dataList = async () => {
    let URL;
    const base_url = window.location.origin;
    if (base_url === "https://merchant.paybybit.net") {
      URL = `${CONSTANT.TEST_BASE_URL}/api/Transaction/listByCustomerWalletAddress?walletAddress=${address}`;
    } else {
      URL = `${CONSTANT.BASE_URL}/api/Transaction/listByCustomerWalletAddress?walletAddress=${address}`;
    }
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const fetchResponse = await fetch(`${URL}`, settings);
      const dataRes = await fetchResponse.json();
      setData(dataRes);
    } catch (error) {
      return error;
    }
  };

  const handleColor = (state: string) => {
    switch (state) {
      case "NoResponse":
        return (
          <span style={{ color: "#FFD600" }} className="statePay">
            NoResponse
          </span>
        );
      case "Approved":
        return (
          <span style={{ color: "#003CF3" }} className="statePay">
            Approved
          </span>
        );
      case "Paid":
        return (
          <span style={{ color: "#5CB56A" }} className="statePay">
            Paid
          </span>
        );
      case "Canceled":
        return (
          <span style={{ color: "#D74632" }} className="statePay">
            Canceled
          </span>
        );
      default:
        return;
    }
  };

  return (
    <SLayout>
      <Column maxWidth={1000} spanHeight paddingBottom={110}>
        <Header connected={connected} address={address} chainId={chainId} />
        <SContent>
          <div className="d-block" style={{ marginTop: 50 }}>
            <img src={back} className="backButton" onClick={() => window.history.back()} />
            <span className="fw-bold" style={{ fontSize: 20 }}>
              Transactions
            </span>
          </div>
          {data ? (
            <div className="table-responsive">
              <a
                style={{ fontSize: 15, color: "#4671F5", display: "block", cursor: "pointer" }}
                className="mb-4 ms-4 mt-4"
                onClick={() => dataList()}
              >
                <img src={refresh} className="me-2" />
                Refresh
              </a>
              <table className="table mt-5 transactionTable" style={{ direction: "rtl" }}>
                <thead>
                  <tr>
                    <td scope="col" className="hiddenMobile">
                      #
                    </td>
                    <td scope="col">Status</td>
                    <td scope="col">Transaction Hash</td>
                    <td scope="col" className="hiddenMobile">
                      Crypto Currency
                    </td>
                    <td scope="col">Amount</td>
                    <td scope="col">Tracking Code</td>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item: any, index: number) => {
                    const EHTBaseAddress = "https://etherscan.io/tx/";
                    const BNBBaseAddress = "https://bscscan.com/tx/";
                    const POLBaseAddress = "https://polygonscan.com/tx/";
                    const baseURL =
                      item.netowrkId === "1"
                        ? EHTBaseAddress
                        : item.netowrkId === "137"
                        ? POLBaseAddress
                        : BNBBaseAddress;
                    return (
                      <tr key={index} style={{ height: 70, verticalAlign: "middle" }}>
                        <td scope="row" className="hiddenMobile">
                          {index}
                        </td>
                        <td>{handleColor(item.state)}</td>
                        <td>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#4099ff" }}
                            href={baseURL + "" + item.transactionHash}
                          >
                            <span className="transactionHash">{item.transactionHash}</span>
                          </a>
                        </td>
                        <td className="hiddenMobile">{item.cryptoCurrency}</td>
                        <td className="cryptoAmount">{item.cryptoAmount}</td>
                        <td>{item.trackingCode}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Loader />
          )}
        </SContent>
      </Column>
      <Footer />
    </SLayout>
  );
};

export default TransactionList;
